<template>
  <el-popover
    :title="title"
    trigger="hover"
    :append-to-body="false"
    :popper-options="{
      positionFixed: true,
    }"
  >
    <!-- 方块 -->
    <div slot="reference" class="pap-cube">
      <p v-if="type == 'empty'">/</p>
      <div v-else class="pap-cube-content">
        <div
          class="pap-cube-content-item"
          :style="{ backgroundColor: topColor }"
        ></div>
        <div
          class="pap-cube-content-item"
          :style="{ backgroundColor: bottomColor }"
        ></div>
      </div>
    </div>
    <!-- 弹窗内容 -->
    <slot name="cube-popover-content"></slot>
  </el-popover>
</template>

<script>
import { Popover } from "element-ui";

export default {
  components: {
    elPopover: Popover,
  },

  props: {
    title: String,
    type: String, //red,green,mix,empty
  },

  computed: {
    topColor() {
      switch (this.type) {
        case "red":
        case "mix":
          return "#F45C50";
        case "green":
          return "#3ABFA9";
        default:
          return null;
      }
    },

    bottomColor() {
      switch (this.type) {
        case "red":
          return "#F45C50";
        case "green":
        case "mix":
          return "#3ABFA9";
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pap-cube {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-content {
    width: 32px;
    height: 32px;

    &-item {
      width: 100%;
      height: 50%;
    }
  }
}
</style>