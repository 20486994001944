
import { DateTool } from "@js/date-tool.js";
class PapMonthModel {
    constructor(model) {
        this.model = model
        this.cubeType = this._initCubeType()
        this.popoverTitle = this._initPopoverTitle()
        this.totalDayCount = this._initTotalDayCount()
        this.timeCount = this._initTimeCount()
        this.above4HourDayCount = this._initAbove4HourDayCount()
        this.below4HourDayCount = this._initBelow4HourDayCount()
        this.notUseDayCount = this._initNotUseDayCount()
        this.averageTimeCount = this._initAverageTimeCount()
        this.averageUseTimeCount = this._initAverageUseTimeCount()
    }

    _initCubeType() {
        switch (this.model.type) {
            case BREATH_MONITOR_TYPE_MONTH.依从性大于等于70:
                return "green"
            case BREATH_MONITOR_TYPE_MONTH.依从性小于70:
                return "red";
            case BREATH_MONITOR_TYPE_MONTH.未开始或其他:
                return "empty";
            default:
                return "empty"
        }
    }

    _initPopoverTitle() {
        if (this.cubeType == "empty") {
            return ""
        } else {
            const startDate = placeholderText(this.model.startTime);
            const endDate = placeholderText(this.model.endTime);
            return `${startDate}-${endDate}`;
        }
    }

    _initTotalDayCount() {
        return placeholderText(this.model.dayTotal?.toFixed())
    }

    _initTimeCount() {
        return placeholderText(DateTool.formatSeconds(this.model.longTime))
    }

    _initAverageTimeCount() {
        return placeholderText(DateTool.formatSeconds(parseInt(this.model.longTime / this.model.dayTotal)))
    }

    _initAverageUseTimeCount() {
        return placeholderText(DateTool.formatSeconds(this.model.avgTime))
    }

    _initAbove4HourDayCount() {
        const dayCount = `${this.model.moreFourHour}`;
        const percent = `${Math.round(
            (this.model.moreFourHour / this.model.dayTotal) * 100
        )}`;
        return `${dayCount}（${placeholderText(percent)}%）`;
    }

    _initBelow4HourDayCount() {
        const dayCount = `${this.model.lessFourHour}`;
        const percent = `${Math.round(
            (this.model.lessFourHour / this.model.dayTotal) * 100
        )}`;
        return `${dayCount}（${placeholderText(percent)}%）`;
    }

    _initNotUseDayCount() {
        const dayCount = `${this.model.notUseDay}`;
        const percent = `${Math.round(
            ((this.model.notUseDay) / this.model.dayTotal) * 100
        )}`;
        return `${dayCount}（${placeholderText(percent)}%）`;
    }

}

export {
    PapMonthModel
}