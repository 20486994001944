<template>
  <div class="type-switch">
    <button
      class="type-switch-item"
      :class="[
        selectedIndex == index
          ? 'type-switch-item-selected'
          : 'type-switch-item-unSelected',
      ]"
      v-for="(item, index) of titleArray"
      :key="index"
      @click="onClick(index)"
    >
      {{ item }}
    </button>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      titleArray: ["近10天", "近6个月"],
      selectedIndex: 0,
    };
  },

  methods: {
    onClick(index) {
      this.selectedIndex = index;
      this.$emit("onSelected", this.titleArray[this.selectedIndex]);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.type-switch {
  display: flex;

  &-item {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 5px;
    width: 112px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &-selected {
      color: white;
      background-color: #2e6be6;
      box-shadow: 0px 3px 6px 1px #2e6be6;
    }

    &-unSelected {
      color: #2e6be6;
      background-color: white;
      border: 1px solid #2e6be6;
    }
  }
}
</style>