<template>
  <page-list-container height="640px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
    @pagination="onPagination" :pageSize="pageSize">
    <div>
      <list-title-bar :dayArray="dayArray"></list-title-bar>
      <list-cell v-for="(item, index) of dataArray" :key="index" :model="item" :isSelected="selectedID == item.id"
        @onSelectedClick="onSelectedClick" @onRouterClick="onRouterClick"></list-cell>
    </div>
  </page-list-container>
</template>

<script>
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import ListTitleBar from "@c/main/pap/pap-title-bar.vue";
import ListCell from "@c/main/pap/6-month/pap-6-month-cell/pap-6-month-cell.vue";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    PageListContainer,
    ListTitleBar,
    ListCell,
  },

  props: {
    keyword: String,
    useType: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      selectedID: undefined,
      // 日期
      dayArray: [],
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 9,
    };
  },
  
  created() {
    this.getPAPMonitorList();
  },

  watch: {
    keyword() {
      this.currentPage = 1;
      this.getPAPMonitorList();
    },
    
    useType() {
      this.currentPage = 1;
      this.getPAPMonitorList();
    }
  },

  methods: {
    onSelectedClick(data) {
      this.selectedID = data.id;
    },

    onReloadList() {
      this.keyword = ""
      this.currentPage = 1
      this.getPAPMonitorList();
    },

    onRouterClick(data) {
      this.$emit("onRouterClick", data);
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getPAPMonitorList();
    },

    // method
    async getPAPMonitorList() {
      const params = {
        hospitalId: this.$store.state.organizationModel?.id,
        keywords: this.keyword,
        type: 2, //按月
        time: 6, //月数
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        useType: this.useType,
      }
      try {
        const data = await this.$api.getPAPMonitorList(params)
        this.dayArray = getDayArray();
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }

      function getDayArray() {
        let today = new Date();
        let lastSixMonths = [];

        for (let i = 5; i >= 0; i--) {
          let month = today.getMonth() - i;
          let year = today.getFullYear();
          if (month < 0) {
            month += 12;
            year -= 1;
          }
          let monthStr = DateTool.dateToStr(new Date(year, month, 1), 'MM/YYYY')
          lastSixMonths.push(monthStr);
        }
        return lastSixMonths;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  &-add-sleep-monitor {
    width: 135px;
    height: 36px;
    margin-right: 16px;
  }

  &-search {
    width: 355px;
    height: 36px;
    margin-left: 29px;
  }
}
</style>