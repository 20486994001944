
import { DateTool } from "@js/date-tool.js";
class PapDayModel {
    constructor(model) {
        this.model = model
        this.cubeType = this._initCubeType()
        this.popoverTitle = this._initPopoverTitle()
        this.useDuration = this._initUseDuration()
        this.ahi = this._initAHI()
        this.averageLeakage = this._initAverageLeakage()
        this.pressure90 = this._initPressure90()
    }

    _initCubeType() {
        switch (this.model.type) {
            case BREATH_MONITOR_TYPE_DAY.正常:
                return this.model.longTime >= 14400 ? "green" : "mix"; //4小时
            case BREATH_MONITOR_TYPE_DAY.当天没有使用:
                return "red";
            case BREATH_MONITOR_TYPE_DAY.未开始:
                return "empty";
            default:
                return null
        }
    }

    _initPopoverTitle() {
        if (this.cubeType == "empty" || this.cubeType == "red") {
            return ""
        } else {
            const startTime = placeholderText(this.model.startTime);
            const endTime = placeholderText(this.model.endTime);
            return `${startTime} —— ${endTime}`;
        }


    }

    _initUseDuration() {
        return placeholderText(DateTool.formatSeconds(this.model.longTime))
    }

    _initAHI() {
        return placeholderText(this.model.ahi)
    }

    _initAverageLeakage() {
        return placeholderText(this.model.leakage, "L/min")
    }

    _initPressure90() {
        return placeholderText(this.model.tpIn, "cmH20")
    }
}

export {
    PapDayModel
}