<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="sleep-cell">
      <cell-unit :style="{ width: '4%' }" :title="userName" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '4%' }" :title="gender" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '6%' }" :title="age" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="sn" @onClick="onRouterClick('Breath')"></cell-unit>
      <cell-unit :style="{ width: '6%' }" :title="startTime" @onClick="onRouterClick('Breath')"></cell-unit>
      <cell-unit :style="{ width: '6%' }" :title="deviceModel" @onClick="onRouterClick('Breath')"></cell-unit>
      <cell-unit :style="{ width: '6%' }" :title="deviceMode" @onClick="onRouterClick('Breath')"></cell-unit>
      <div class="sleep-cell-mask-unit" :style="{ width: '10%' }" @click="onRouterClick('Breath')">
        <p v-if="maskArray[0]">{{ maskArray[0] }}</p>
        <p v-if="maskArray[1]">{{ maskArray[1] }}</p>
      </div>
      <slot name="right"></slot>
    </div>
  </list-cell>
</template>

<script>
  import CellUnit from "@c/common/paging-list/cell-unit.vue";
  import ListCell from "@c/common/paging-list/list-cell.vue";
  import { findKey } from "@js/object-tool.js";
  export default {
    components: {
      ListCell,
      CellUnit,
    },

    props: {
      model: Object,
      isSelected: Boolean,
    },

    computed: {
      userName() {
        return placeholderText(this.model.userName);
      },

      gender() {
        return placeholderText(findKey(USER_GENDER, this.model.sex));
      },

      age() {
        return placeholderText(this.model.age?.toFixed());
      },

      sn() {
        return placeholderText(this.model.sn);
      },

      startTime() {
        return placeholderText(this.model.startTime);
      },

      deviceModel() {
        return placeholderText(this.model.deviceModel);
      },

      deviceMode() {
        return placeholderText(this.model.parameterMode);
      },

      maskArray() {
        let parameterMaskStr = this.model.parameterMask
        if (parameterMaskStr) {
          return this.model.parameterMask.split(' ')
        } else {
          return ['-']
        }
      },
    },

    methods: {
      onSelectClick() {
        this.$emit("onSelectedClick", {
          id: this.model.id,
        });
      },

      onRouterClick(moduleName) {
        this.$emit("onRouterClick", {
          uid: this.model.uid,
          moduleName,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sleep-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    /deep/.cell-unit-title {
      white-space: normal;
      overflow: visible;
    }

    &-mask-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    &-button-container {
      display: flex;
      align-items: center;
      justify-content: center;

      &-content {
        display: flex;

        &-right-button {
          margin-left: 21px;
        }
      }

      &-invalid {
        color: #b3b3b3;
        font-size: 14px;
      }
    }
  }
</style>