<template>
  <div class="pap-title-bar">
    <list-title-section-bar :titleArray="titleArray">
      <template v-slot:right>
        <div
          class="pap-title-bar-date"
          @mouseout="mouseout"
          @mouseover="mouseover"
        >
          <p
            v-for="item of dayArray"
            :key="item"
            class="pap-title-bar-date-unit"
            :class="{ 'pap-title-bar-date-unit-hover': isHover }"
            :style="{ width: `${(1 / dayArray.length) * 100}%` }"
          >
            {{ item }}
          </p>
        </div>
      </template>
    </list-title-section-bar>
  </div>
</template>
    
    <script>
import ListTitleSectionBar from "@c/common/paging-list/list-title-section-bar/list-title-section-bar.vue";

export default {
  components: { ListTitleSectionBar },

  props: {
    dayArray: Array,
  },

  data() {
    return {
      isHover: false,
    };
  },

  computed: {
    titleArray() {
      return [
        [
          { name: "姓名", percent: 4 },
          { name: "性别", percent: 4 },
          { name: "年龄", percent: 6 },
        ],
        [
          { name: "设备序列号", percent: 8 },
          { name: "开始时间", percent: 6 },
          { name: "设备型号", percent: 6 },
          { name: "工作模式", percent: 6 },
          { name: "面罩类型", percent: 10 },
        ],
      ];
    },
  },

  methods: {
    mouseover() {
      this.isHover = true;
    },

    mouseout() {
      this.isHover = false;
    },
  },
};
</script>
    
<style lang="scss" scoped>
.pap-title-bar {
  display: flex;

  &-date {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-unit {
      display: flex;
      align-items: center;
      justify-content: center;

      &-hover {
        color: #2e6be6;
      }
    }
  }
}
</style>