<template>
  <pap-cell
    :model="model"
    :isSelected="isSelected"
    @onSelectedClick="onSelectedClick"
    @onRouterClick="onRouterClick"
  >
    <template v-slot:right>
      <div
        v-for="(item, index) of cubeDataArray"
        :key="index"
        class="cube-container"
        :style="{ width: '8.33%' }"
      >
        <pap-cube :type="item.cubeType" :title="item.popoverTitle">
          <!-- popover -->
          <template v-slot:cube-popover-content>
            <p v-if="item.cubeType == 'empty'" class="cube-popover-empty">
              未开始使用
            </p>
            <div v-else class="cube-popover-content">
              <div class="cube-popover-content-row">
                <key-value-unit
                  title="总天数"
                  :value="item.totalDayCount"
                ></key-value-unit>
                <key-value-unit
                  class="cube-popover-content-row-right"
                  title="≥4小时天数"
                  :value="item.above4HourDayCount"
                ></key-value-unit>
              </div>
              <div class="cube-popover-content-row">
                <key-value-unit
                  title="总时长"
                  :value="item.timeCount"
                ></key-value-unit>
                <key-value-unit
                  class="cube-popover-content-row-right"
                  title="＜4小时天数"
                  :value="item.below4HourDayCount"
                ></key-value-unit>
              </div>
              <div class="cube-popover-content-row">
                <key-value-unit
                  class="cube-popover-content-row-right"
                  title="未使用天数"
                  :value="item.notUseDayCount"
                ></key-value-unit>
              </div>
              <div class="cube-popover-content-row">
                <key-value-unit
                  title="全部平均时长"
                  :value="item.averageTimeCount"
                ></key-value-unit>
                <key-value-unit
                  class="cube-popover-content-row-right"
                  title="平均时长"
                  :value="item.averageUseTimeCount"
                ></key-value-unit>
              </div>
            </div>
          </template>
        </pap-cube>
      </div>
    </template>
  </pap-cell>
</template>

<script>
import PapCell from "@c/main/pap/pap-cell.vue";
import CellUnit from "@c/common/paging-list/cell-unit.vue";
import PapCube from "@c/main/pap/pap-cube.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit.vue";
import { PapMonthModel } from "@c/main/pap/6-month/pap-6-month-cell/pap-month-model.js";
export default {
  components: {
    PapCell,
    CellUnit,
    KeyValueUnit,
    PapCube,
  },

  props: {
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    cubeDataArray() {
      return this.model.report.map((item) => {
        const cubeDayModel = new PapMonthModel (item);
        return cubeDayModel;
      });
    },
  },

  methods: {
    onSelectedClick(data) {
      this.$emit("onSelectedClick", data);
    },

    onRouterClick(data) {
      this.$emit("onRouterClick", data);
    },
  },
};
</script>

<style lang="scss" scoped>

.cube-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cube-popover-empty {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cube-popover-content {
  width: 400px;

  &-row {
    position: relative;
    display: flex;
    height: 25px;

    ::v-deep .statistics-data-unit {
      height: 25px;
    }

    &-right {
      position: absolute;
      left: 194px;
    }
  }
}
</style>


<style lang="scss">
;
.el-popover__title {
  color: #666666;
  font-weight: 300;
}
</style>