<template>
  <div>
    <list-shell ref="listShell" title="PAP治疗列表" model="left">
      <template v-slot:add-left>
        <type-switch @onSelected="onSelected" />
      </template>
      <template v-slot:add-right>
        <condition-filter :filter-value.sync="timeFilter"></condition-filter>
        <main-input class="sleep-search" placeholder="支持姓名、手机号码、序列号查询" v-model:inputValue="keyword"
          @onSearch="onSearch"></main-input>
      </template>
      <template v-slot:content>
        <ten-day ref="tenDay" v-if="listTitle == '近10天'" :keyword="keyword" :use-type="timeFilter"
          @onRouterClick="onRouterClick"></ten-day>
        <six-month ref="sixMonth" v-if="listTitle == '近6个月'" :keyword="keyword" :use-type="timeFilter"
          @onRouterClick="onRouterClick"></six-month>
      </template>
    </list-shell>
  </div>
</template>

<script>
import ListShell from "@c/main/common/list-shell/list-shell.vue";
import TypeSwitch from "@c/main/pap/type-switch.vue";
import MainInput from "@c/main/patient/input.vue";
import TenDay from "@c/main/pap/10-day/10-day.vue";
import SixMonth from "@c/main/pap/6-month/6-month.vue";
import ConditionFilter from "@c/main/pap/condition-filter.vue";


export default {
  components: {
    ListShell,
    TypeSwitch,
    MainInput,
    TenDay,
    SixMonth,
    ConditionFilter,
  },

  data() {
    return {
      //模块切换
      listTitle: "近10天",
      //搜索
      keyword: "",
      //条件筛选
      timeFilter: 0,
    };
  },

  computed: {
    organizationModel() {
      return this.$store.state.organizationModel;
    }
  },

  watch: {
    organizationModel(newVal, oldVal) {
      if (this.keyword == "") {
        if (newVal?.id != oldVal?.id) {
          this.smartReloadList()
        }
      } else {
        this.keyword = ""
      }
    },

    keyword(newVal, oldVal) {
      if (newVal?.id != oldVal?.id) {
        this.smartReloadList()
      }
    }
  },

  methods: {
    //模块切换
    onSelected(listTitle) {
      this.listTitle = listTitle;
    },

    //关键词搜索
    onSearch(value) {
      this.keyword = value;
    },

    //路有跳转
    onRouterClick(data) {
      this.$refs.listShell.openUserMain(data.uid, data.moduleName);
    },

    smartReloadList() {
      if (this.listTitle == '近10天') {
        this.$refs.tenDay.onReloadList()
      } else {
        this.$refs.sixMonth.onReloadList()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  &-add-sleep-monitor {
    width: 135px;
    height: 36px;
    margin-right: 16px;
  }

  &-search {
    width: 355px;
    height: 36px;
    margin-left: 29px;
  }
}
</style>