<template>
  <page-list-container height="640px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
    :pageSize="pageSize" @pagination="onPagination">
    <div>
      <list-title-bar :day-array="barDayArray"></list-title-bar>
      <list-cell v-for="(item, index) of dataArray" :key="index" :model="item" :isSelected="selectedID == item.id"
        @onSelectedClick="onSelectedClick" @onRouterClick="onRouterClick"></list-cell>
    </div>
  </page-list-container>
</template>

<script>
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import ListTitleBar from "@c/main/pap/pap-title-bar.vue";
import ListCell from "@c/main/pap/10-day/pap-10-day-cell/pap-10-day-cell.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    PageListContainer,
    ListTitleBar,
    ListCell,
  },

  props: {
    keyword: String,
    useType: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      selectedID: undefined,
      // 日期
      barDayArray: [],
      // 列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 9,
    };
  },

  created() {
    this.getPAPMonitorList();
  },

  watch: {
    keyword() {
      this.currentPage = 1;
      this.getPAPMonitorList();
    },

    useType() {
      this.currentPage = 1;
      this.getPAPMonitorList();
    }
  },


  methods: {
    onSelectedClick(data) {
      this.selectedID = data.id;
    },

    onReloadList() {
      this.keyword = ""
      this.currentPage = 1
      this.getPAPMonitorList();
    },

    onRouterClick(data) {
      this.$emit("onRouterClick", data);
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getPAPMonitorList();
    },

    // method
    async getPAPMonitorList() {
      const params = {
        hospitalId: this.$store.state.organizationModel?.id,
        keywords: this.keyword,
        type: 1, //按天
        time: 10, //天数
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        useType: this.useType,
      }
      try {
        const data = await this.$api.getPAPMonitorList(params)
        this.barDayArray = getBarDayArray();
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }

      function getBarDayArray() {
        let today = new Date();
        let lastTenDays = [];
        for (let i = 10; i >= 1; i--) {
          let pastDate = new Date(today);
          pastDate.setDate(today.getDate() - i);
          let dateStr = DateTool.dateToStr(pastDate, 'DD/MM')
          lastTenDays.push(dateStr);
        }
        return lastTenDays;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  &-add-sleep-monitor {
    width: 135px;
    height: 36px;
    margin-right: 16px;
  }

  &-search {
    width: 355px;
    height: 36px;
    margin-left: 29px;
  }
}
</style>