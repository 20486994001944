<template>
    <el-radio-group :value="filterValue" @input="onChanged" class="filter">
        <el-radio :label="0">全部</el-radio>
        <el-radio :label="1">未使用</el-radio>
        <el-radio :label="2">{{ '<4小时' }}</el-radio>
                <el-radio :label="3">{{ '≥4小时' }}</el-radio>
    </el-radio-group>
</template>

<script>
import { Radio, RadioGroup } from 'element-ui'
export default {
    components: {
        ElRadio: Radio,
        ElRadioGroup: RadioGroup,
    },
    props: {
        filterValue: Number,
    },
    methods: {
        onChanged(value) {
            this.$emit("update:filterValue", value);
        }
    },
}
</script>
<style scoped lang='scss'>
.filter {
    .el-radio {
        margin-right: 20px;
    }
    &>.el-radio:last-child {
        margin-right: 0px;
    }
}
</style>